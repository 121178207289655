<template>
  <div class="supplierList">
    <div class="content">
      <div class="pop-content">
        <!-- 供应商信息 -->
        <el-tabs v-model="tabChecked" class="demo-tabs" @tab-change="tabCheck">
          <el-tab-pane
              :label="'正常（' + countInfo.normal + '）'"
              name="normalList"
          >
          </el-tab-pane>
          <el-tab-pane :label="'作废（' + countInfo.discard + '）'" name="voidList">
          </el-tab-pane>
        </el-tabs>
        <!-- 列表 -->
        <div class="normalList">
          <div class="queryBoxOne">
            <div class="btnBox">
              <el-button type="primary" @click="add" v-if='tabChecked !="voidList"'>
                <el-icon>
                  <Plus/>
                </el-icon>
                <span class="text">新增</span>
              </el-button>
            </div>
            <div class="queryItem">
              <el-input
                  class="w240"
                  placeholder="供应商名称"
                  v-model="formData.query"
                  @keydown.enter="getList"
                  clearable
                  v-elInput
              >
              </el-input>
              <div class="queryBtn">
                <el-button type="primary" @click="getList"
                >
                  <el-icon>
                    <Search/>
                  </el-icon
                  >
                </el-button>
              </div>
            </div>
          </div>
          <div class="tabList">
            <!-- 列表 -->
            <el-table
                :data="tableData"
                style="width: 100%"
                height="100%"
                class="zh-no-table-border"
                :row-class-name="tableRowClassName"
                :cell-style="{border:'none'}"
                :header-row-style="{border:'none'}"
                @row-click="openInfoDia"
            >
              <el-table-column label="序号" width="100" align="center">
                <template #default="scope">
                  <div class="table_text">{{ ((pageInfo.pageNumber - 1) * pageInfo.pageSize) + scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column width="300" prop="supplierName" label="供应商名称" align="center"
                               class-name="table_text2"
                               show-overflow-tooltip/>
              <el-table-column
                  prop="contactUserName"
                  label="联系人"
                  width="200"
                  align="center"
                  show-overflow-tooltip
                  row-class-name="table_text"
              >
                <template #default="scope">
                  {{ scope.row.contactUserName }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="contactUserPhone"
                  label="联系电话"
                  width="200"
                  align="center"
                  show-overflow-tooltip
                  class-name="table_text"
              >
                <template #default="scope">{{ scope.row.contactUserPhone }}</template>
              </el-table-column>
              <el-table-column
                  label="地址"
                  width="200"
                  align="center"
                  class-name="table_text"
                  show-overflow-tooltip
              >
                <template #default="scope">{{ scope.row.address }}</template>
              </el-table-column>
              <el-table-column
                  label="变更人"
                  width="200"
                  align="center"
                  class-name="table_text"
                  show-overflow-tooltip
              >
                <template #default="scope">{{ scope.row.updateUserName }}
                </template>
              </el-table-column>
              <el-table-column
                  label="变更时间"
                  align="center"
                  class-name="table_text"
              >
                <template #default="scope">{{ $filters.timeFormat(scope.row.updateTime, 1) }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="mainPage">
          <el-pagination
              v-model:current-page="pageInfo.pageNumber"
              layout="total, prev, pager, next , jumper"
              :total="pageInfo.total"
              v-model:page-size='pageInfo.pageSize'
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog
      v-model="showDia"
      :title="addInfo.supplierId ? '编辑供应商信息' : '新增供应商信息'"
      width="920px"
      class="supplierDia"
      align-center
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="query">
          <div class="item">
            <div class="name"><span class="red">*</span>供应商名称</div>
            <div class="input" v-if='!addInfo.supplierId'>
              <el-input
              v-elInput
                class="queryInput"
                v-model="addInfo.supplierName"
                placeholder="请输入供应商名称"
                maxlength="100"
              ></el-input>
            </div>
            <div class="text" v-else>
              {{addInfo.supplierName }}
            </div>
          </div>
          <div class="item">
            <div class="name">联系人</div>
            <div class="input">
              <el-input
              v-elInput
                class="queryInput"
                v-model="addInfo.contactUserName"
                placeholder="请输入联系人名称"
                  maxlength="100"
              ></el-input>
            </div>
          </div>
          <div class="item">
            <div class="name">联系人电话</div>
            <div class="input">
              <el-input
              v-elInput
                class="queryInput"
                v-model="addInfo.contactUserPhone"
                placeholder="请输入联系人电话"
                maxlength="13"
                onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')"
              ></el-input>
            </div>
          </div>
          <div class="item">
            <div class="name">公司地址</div>
            <div class="input">
              <el-input
              v-elInput
                class="queryInput"
                v-model="addInfo.address"
                placeholder="市/区/街道"
                maxlength="100"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="clsoeShowDia">取消</el-button>
        <el-button class="main" type="primary" @click="handleOkAdd" :disabled="!isOk">{{
          addInfo.supplierId ? "确认编辑" : "确认新增"
        }}</el-button>
      </template>
    </el-dialog>
    <el-dialog
      v-model="infoDia"
      title="供应商信息"
      width="920px"
      class="supplierDia"
      align-center
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="query">
          <div class="item">
            <div class="name"><span class="red">*</span>供应商名称</div>
            <div class="text">
              {{goodsInfo.supplierName || '暂无'}}
            </div>
          </div>
          <div class="item">
            <div class="name">联系人</div>
            <div class="text">
              {{goodsInfo.contactUserName || '暂无'}}
            </div>
          </div>
          <div class="item">
            <div class="name">联系人电话</div>
            <div class="text">
            {{goodsInfo.contactUserPhone || '暂无'}}
            </div>
          </div>
          <div class="item">
            <div class="name">公司地址</div>
            <div class="text">
             {{goodsInfo.address || '暂无'}}
            </div>
          </div>
        </div>
      </div>
      <template #footer v-if='tabChecked == "normalList"'>
        <el-button @click="discard(goodsInfo)" class="zh-btn btn1">作废</el-button>
        <el-button class="main btn1" @click="add(goodsInfo)" type="primary" >编辑</el-button>
      </template>
      <template #footer v-else>
        <el-button @click="resume(goodsInfo)" class="zh-btn btn1">恢复</el-button>
      </template>
    </el-dialog>
    <Modal
      :show="confirmDIa"
      :title="confirmInfo.title"
      :content="confirmInfo.content"
      @ok="handleOk"
      @close="handleClose"
    ></Modal>
  </div>
</template>
<script>
import {computed, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {ArrowDown, Plus, Search} from "@element-plus/icons-vue";
import Modal from "@/components/modal/index.vue";
import {ElMessageBox} from "element-plus";
import {
  supplierAdd,
  supplierCensus,
  supplierDiscard,
  supplierEdit,
  supplierList,
  supplierLIstDiscard,
  supplierResume,
} from "@/utils/api/supplier.js";
import {tableRowClassName} from '@/utils/tool.js'
import {usePagination} from "@utils/tool/page";

export default {
  name: "supplierList",
  components: {
    Plus,
    ArrowDown,
    Search,
    Modal,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const tabChecked = ref("normalList");
    const tabCheck = function (tab) {
      formData.query = "";
      getList();
      getCount();
    };
    const showDia = ref(false); // 控制弹窗是否展示
    const confirmDIa = ref(false); // 控制确认弹窗是否打开
    const infoDia = ref(false);//详情弹窗
    const goodsInfo = reactive({
      supplierName: "",
      contactUserName: "",
      contactUserPhone: "",
      address: "",
      supplierId: "",
    });
    //打开详情弹窗
    const openInfoDia = (row) => {
      infoDia.value = true;
      goodsInfo.supplierName = row.supplierName;
      goodsInfo.contactUserName = row.contactUserName;
      goodsInfo.contactUserPhone = row.contactUserPhone;
      goodsInfo.address = row.address;
      goodsInfo.supplierId = row.supplierId;

    };
    //关闭详情弹窗
    const clsoeInfoDia = () => {
      infoDia.value = false;
    };
    const confirmInfo = reactive({
      title: "",
      content: "",
      supplierId: "",
      type: 0,
    });
    const {pageInfo} = usePagination()
    const handleOk = async () => {
      let api = confirmInfo.type == 1 ? supplierDiscard : supplierResume;
      let res = await api({
        supplierId: confirmInfo.supplierId,
      });
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("操作成功");
      tabCheck();
      confirmDIa.value = false;
      infoDia.value = false;
    };
    const handleClose = (e) => {
      confirmDIa.value = e;
    };
    const formData = reactive({
      query: "",
    });
    //新增修改信息
    const addInfo = reactive({
      contactUserName: "", //联系人姓名
      address: "", //地址
      contactUserPhone: "", //联系人电话
      supplierId: "", //供应商id
      supplierName: "", //供应商名称
    });
    //作废
    const discard = async (e) => {
      confirmInfo.title = "作废";
      confirmInfo.content = "确认该供应商作废";
      confirmInfo.supplierId = e.supplierId;
      confirmInfo.type = 1; // 作废
      confirmDIa.value = true;
    };
    //恢复
    const resume = async (e) => {
      confirmInfo.title = "恢复";
      confirmInfo.content = "确认该供应商恢复使用";
      confirmInfo.supplierId = e.supplierId;
      confirmInfo.type = 2; // 恢复
      confirmDIa.value = true;
    };
    //编辑
    const add = async (e) => {
      clearAddInfo();
      if (e) {
        //赋值 供应商信息
        addInfo.contactUserName = e.contactUserName;
        addInfo.address = e.address;
        addInfo.contactUserPhone = e.contactUserPhone;
        addInfo.supplierId = e.supplierId;
        addInfo.supplierName = e.supplierName;
      }
      showDia.value = true;
    };
    //确认新增编辑
    const handleOkAdd = async () => {
      let api = addInfo.supplierId ? supplierEdit : supplierAdd;
      if (!addInfo.supplierName) {
        proxy.$message.warning("请填写供应商名称");
        return;
      }
      if (addInfo.supplierName.length < 2) {
        proxy.$message.warning("供应商名称最少2个字");
        return;
      }
      let res = await api(addInfo);
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      proxy.$message.success("操作成功");
      tabCheck();
      showDia.value = false;
      infoDia.value = false;
    };
    const clearAddInfo = () => {
      const keys = Object.keys(addInfo);
      let obj = {};
      keys.forEach((item) => {
        obj[item] = "";
      });
      Object.assign(addInfo, obj);
    };
    const tableData = ref([]);
    const data = reactive({
      countInfo: {
        normal: 0,
        discard: 0,
      },
    });
    //获取数量统计
    const getCount = async () => {
      let res = await supplierCensus();
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      data.countInfo.normal = res.data.normal;
      data.countInfo.discard = res.data.discard;
    };
    getCount();
    //获取列表
    const getList = async () => {
      let api =
        tabChecked.value === "normalList" ? supplierList : supplierLIstDiscard;
      let obj = Object.assign(pageInfo, formData);
      let res = await api(obj);
      if (res.code != 0) {
        proxy.$message.error(res.msg);
        return;
      }
      tableData.value = res.data?.list || [];
      // pageInfo.total = res.data?.total || 0;
      pageInfo.total = res.data?.total || 0;
    };
    getList();
    const handleCurrentChange = (val) => {
      pageInfo.pageNumber = val
      getList();
    };
    const clsoeShowDia = () => {
      ElMessageBox.confirm("供应商信息未保存，是否确认取消？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
        showDia.value = false;
      });
    };
    const isOk = computed(()=>{
      return addInfo.supplierName
    })
    return {
      isOk,
      clsoeShowDia,
      tabChecked,
      tabCheck,
      ...toRefs(data),
      tableData,
      pageInfo,
      showDia,
      formData,
      handleCurrentChange,
      getList,
      discard,
      resume,
      addInfo,
      add,
      handleOkAdd,
      confirmDIa,
      confirmInfo,
      handleClose,
      handleOk,
      tableRowClassName,
      openInfoDia,
      infoDia,
      clsoeInfoDia,
      goodsInfo
    };
  },
};
</script>

<script setup></script>

<style lang="scss" scoped>
.supplierList {
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
     >.content{
        border-radius: 16px;
        background: #fff;
        padding: 12px 32px;
        height: 100%;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;

       .pop-content {
         position: relative;
         height: 100%;
       }
    }
  .normalList {
    position: relative;
    height: calc(100vh - 268px);
  }
  .tabList {
    margin-top: 34px;
    height: calc(100% - 40px);
    .btn {
      display: inline-block;
      color: $fsColor3;
    }
  }
}

.supplierDia {
  .content {
    padding: 0 16px;

  }
}
.query {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .item {
    width: 50%;
    margin-bottom: 20px;
    font-weight: 400;

    .name {
      color: #737480;
    }
    .red {
      color: #e54e4e;
      position: relative;
      top: 2px;
    }
    .queryInput {
      width: 360px;
    }
    .input {
      position: relative;
      margin-top: 10px;
      .addBtn {
        height: 36px;
        line-height: 36px;
        position: absolute;
        left: 316px;
        top: 0;
        color: $fsColor3;
        margin-right: 10px;
        font-weight: normal;
        cursor: pointer;
      }
      .avatar-uploader {
        width: 168px;
        height: 126px;
        background: #f2f4f7;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar-uploader-icon {
          font-size: 40px;
          line-height: 126px;
          color: #dcdee0;
        }
      }
    }
    .text{
      line-height: 1;
      font-size: 14px;
      color: $fsColor;
      margin-top: 20px;
      margin-bottom: 20px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
:deep(.el-table__row) {
  cursor: pointer;
}
</style>
