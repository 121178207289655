import request from '../http'
import {managerUrl} from '../api'
//客户信息

/*
供应商查询接口
@param {string} supplierName 供应商名称
*/

export function supplierQuery(data){
  return request({
    url:managerUrl + "/carton/product/supplier/query",
    method:'post',
    data
  })
}

//供应商数量统计
export function supplierCensus(data){
  return request({
    url:managerUrl + "/carton/product/supplier/census",
    method:'post',
    data
  })
}

//供应商列表（正常）
export function supplierList(data){
  return request({
    url:managerUrl + "/carton/product/supplier/list",
    method:'post',
    data
  })
}

//供应商列表（作废）
export function supplierLIstDiscard(data){
  return request({
    url:managerUrl + "/carton/product/supplier/list/discard",
    method:'post',
    data
  })
}

//供应商作废
export function supplierDiscard(data){
  return request({
    url:managerUrl + "/carton/product/supplier/discard",
    method:'post',
    data
  })
}

//供应商恢复
export function supplierResume(data){
  return request({
    url:managerUrl + "/carton/product/supplier/resume",
    method:'post',
    data
  })
}

//供应商新增
export function supplierAdd(data){
  return request({
    url:managerUrl + "/carton/product/supplier/add",
    method:'post',
    data
  })
}

//供应商编辑
export function supplierEdit(data){
  return request({
    url:managerUrl + "/carton/product/supplier/edit",
    method:'post',
    data
  })
}